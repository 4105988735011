.category{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.category:nth-of-type(1){
    margin-top: 50px;
}

.categoryName{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Philosopher', sans-serif;
    text-transform: uppercase;
    font-size: 28px;
    color: darkgoldenrod;
    margin-bottom: 50px;
    align-items: center;
}

hr{
    width: 30vw;
    height: 0;
    margin: 0 40px;
}

.categorySlider{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}