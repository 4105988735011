.Popup {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 10;
    padding-top: 80px;
    width: 100vw;
    box-sizing: border-box;
    height: 100vh;
}

.popbox{
    position: fixed;
    padding: 20px;
    top: 5vh;
    width: 30vw;
    height: 20vh;
    background-color: palegreen;
    border: 1px solid palegreen;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slide 5s;
    animation-fill-mode: forwards;
}

@keyframes slide {
    0% {margin-top: -45vh;}
    10% {margin-top: 0;}
    90% {margin-top: 0;}
    100% {margin-top: -45vh;}
}
