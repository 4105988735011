.ContactUs{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    background-color: lightgray;
    padding: 100px 0;
}

.ContactUs img{
    width: 500px;
    height: 100%;
}

.contactQuote {
    font-size: 40px;
}

.contactForm{
    display: flex;
    height: 500px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px 50px;
}

.contactForm input,textarea{
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
    width: 300px;
    border-radius: 5px;
    margin-bottom: 10px;
    padding-left: 10px;
    border: 1px solid gray;
}

.contactForm input{
    height: 28px;
}

.contactForm button{
    width: fit-content;
    cursor: pointer;
    padding: 15px 35px;
    background-color: dodgerblue;
    color:white;
    border: 1px solid white;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .ContactUs{
        flex-direction: column-reverse;
    }

    .ContactUs img{
        width: 100%;
    }

    .contactForm{
        padding: 5px 4vw;
    }
}