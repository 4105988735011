.description{
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5vh;
  padding: 0 5vw;
}

.description img{
  width: 500px;
  height: auto;
}

.product-data{
  width: 500px;
  padding: 1vw 5vw;
  display: flex;
  flex-direction: column;
}

.product-name{
  font-family: 'Philosopher', sans-serif;
  font-size: 30px;
  color: rgb(45, 45, 45);
  font-weight: 100;
  text-decoration: underline;
}

.product-price{
  margin-top: 6vh;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
}

.product-description{
  margin-top: 6vh;
  font-size: 20px;
  white-space: pre-wrap;
  font-family: 'Nunito', sans-serif;
}

@media only screen and (max-width:768px){
  .description img{
    width: 90vw;
  }

  .product-description, .product-price{
    margin-top: 3vh;
  }
}