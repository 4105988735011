.AboutUs {
    margin-top: 10vh;
    padding: 10px;
    z-index: -1;
}

.aboutTop{
    display: flex;
    justify-content: space-evenly;
}

.aboutTop img{
    height: 100%;
    width: 50vw;
    padding-top: 50px;
}

.aboutTop > div{
    width: 40vw;
    padding: 50px 20px;
}

.aboutTop h1{
    font-size: 48px;
}

.aboutTop p{
    font-size: 18px;
    padding-top: 20px;
}

@media only screen and (max-width: 768px){
    .AboutUs{
        overflow: hidden;
    }
    .AboutUs > img {
        transform: scale(1.5,1.5);
    }
    .aboutTop{
        flex-direction: column-reverse;
    }

    .aboutTop > div{
        width: 80vw;
    }

    .aboutTop h1 {
        text-align: center;
    }

    .aboutTop img {
        width: auto;
        padding-top: 3vw;
    }
}