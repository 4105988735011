.productCard{
    width: 245px;
    height: auto;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Radio Canada', sans-serif;
    margin: 5px;
}

.productCard img{
    width: 100%;
    height: auto;
    border: 3px solid DarkGoldenRod;
    cursor: pointer;
}

.productName{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
}

.productPrice{
    font-size: 14px;    
    font-weight: 600;
    margin-left: 20px;
}

@media only screen and (max-width:768px){
    .productCard{
        width: 150px;
    }

    .productName{
        font-size: 12px;
        margin-left: 10px;
        height: 30px;
    }

    .productPrice{
        margin-left: 10px;
        font-size: 11px;
    }
}