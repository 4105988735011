.footer{
    height: 100px;
    width: 100%;
    border-top: 1px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
}

.socialMedia {
    margin-left: 100px;
    display: flex;
    width: 150px;
    justify-content: space-evenly;
}

.socialMedia img{
    width: 20px;
    height: 20px;
}

.copyright{
    font-size: 14px;
}

.footerLinks{
    display: flex;
    width: 20%;
    justify-content: space-between;
    padding: 0 20px;
}

@media only screen and (max-width: 768px){
    .footer{
        flex-direction: column;
        justify-content: space-evenly;
    }

    .socialMedia{
        margin-left: 0;
    }

    .footerLinks{
        padding: 0;
        width: 100%;
        justify-content: space-evenly;
    }
}