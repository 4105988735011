.extra-mob{
    display: none;
}

.title-mob{
    display: none;
}

.header {
    position: fixed;
    width: 96%;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 2%;
    background-color: white;
}

a{
    text-decoration: none;
    color: black;
}

.title img{
    width: 300px;
    cursor: pointer;
}

.menu{
    width: 30vw;
    font-family: 'Radio Canada', sans-serif;
    font-size: 20px;
}

.menu ul{
    display: flex;
    justify-content: space-around;
    list-style-type: none;
}

.menu li{
    cursor: pointer;
}

.menu li:hover{
    text-decoration: underline;
}

.extra{
    width: 15vw;
    display: flex;
    justify-content: space-evenly;
}

.extra button{
    padding: 5px 10px;
    background-color: white;
    border: none;
    font-family: 'Radio Canada', sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .header{
        height: fit-content;
        padding: 0;
        width: 100vw;
        background-color: rgb(28,27,23,1);
    }

    .title{
        display: none;
    }

    .menu{
        display: none;
    }

    .extra{
        display: none;
    }

    .title-mob{
        display: inline;
    }

    .title-mob img{
        width: 200px;
        cursor: pointer;
    }

    .extra-mob{
        display: flex;
        width: 20vw;
        align-items: center;
        justify-content: right;
        padding-right: 10px;
    }

    .extra-mob button{
        border: none;
        background-color: transparent;
        filter: invert(1);
    }

    .extra-mob img{
        width: 20px;
    }

    .menu-dropdown{
        position: absolute;
        z-index: 1;
        width: 70vw;
        height: 100vh;
        right: 0;
        top: 0;
        font-family: 'Radio Canada', sans-serif;
        border: 1px solid rgb(28,27,23,1);
        background-color: rgb(28,27,23,1);
        border-radius: 2px;
    }

    .menu-dropdown ul{
        list-style-type: none;
        margin-top: 30px;
        padding: 20px;
    }

    .menu-dropdown li{
        margin: 9px;
        padding: 9px 10px;
        border-bottom: 1px solid white;
    }

    .menu-dropdown li:nth-last-of-type(1){
        border-bottom: none;
    }

    .menu-dropdown a{
        color: white;
    }

    .menu-dropdown span {
        color: white;
        cursor: pointer;
        float: right;
        padding: 15px;
    }
}