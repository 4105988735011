*{
  margin: 0;
  padding: 0;
}

.App{
  display: flex;
  flex-direction: column;
  font-family: 'Radio Canada', sans-serif;
}

.coming-soon{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-top: 15vh;
  height: 60vh;
  margin-left: 20px;
}

.whitescreen{
  position: absolute;
  top:0;
  left:0;
  background-color: white;
  opacity: 0.5;
  z-index: 1;
  width: 100%;
  height: 100%;
}