.banner {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.Top{
    position: relative;
    z-index: -1;
}

.bannerTitle {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4% 0 0 5%;
    color: white;
    font-size: 4vw;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    background-image: linear-gradient(to left,transparent,black 60%, black 100%);
}