.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-radius: 50%;
    width: 21px;
    height: 21px;
    animation: spin 1s linear infinite;
    margin-left: 6px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }